body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #444;
}

a {
  color: #400095;
  transition: .4s ease-in-out;
  font-weight: 300;

  &:hover {
    color: #965ce4;
  }
}

.footer {
  background: #f4f4f4;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    height: 60px;
    width: auto;
    opacity: 0.1;
  }

  .copy {
    font-size: 12px;
    opacity: 0.15;
    margin-top: 5px;
  }
}

h1 {
  font-size: 48px;
  color: #4c4c4c;
  margin-bottom: 20px;
  font-weight: 300;
}

h2 {
	margin-bottom: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 70px;
}

h3 {
	//font-weight: bold;
	margin-bottom: 20px;
	border-bottom: 1px solid #DDD;
	padding-bottom: 5px;
}

p {
  color: #444;
  font-size: 21px;
  line-height: 1.5;
  font-weight: 100;
}

li {
	line-height: 1.5;
  font-weight: 100;
}

ol {
  margin:0 0 1.5em;
  padding:0;
  counter-reset:item;
}

ol>li {
  margin:0;
  padding: 10px 0 0 2em;
  text-indent:-2em;
  list-style-type:none;
  counter-increment:item;
}

ol>li:before {
  display:inline-block;
  width:1.5em;
  padding-right:0.5em;
  font-weight:bold;
  text-align:right;
  content:counter(item) ".";
}

ul {
	margin-left: 0;
	margin-bottom: 40px;
}


ul li {
	list-style-type: disc;
	padding: 5px 0px;

}

table {
	margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

td, th {
	padding: 15px;
	border-bottom: 1px solid #eee;
}

th {
	font-weight: bold;
  color: white;
  background: #400095;
}

td {
  font-weight: 300;
}

.screenshot {
	min-height: 400px;
	background: #CCC;
}

.opening {
  width: calc(100vw - 100px);
  height: calc(60vh - 100px);
  display: flex;
  justify-content: center;
  padding: 50px;
  background: url('../img/website-desktop.jpg');
  background-position: top center;
  background-size: cover;
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    content: '';
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 98%, rgba(0,0,0,1) 101%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 98%,rgba(0,0,0,1) 101%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 98%,rgba(0,0,0,1) 101%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  }

  img {
    width: auto;
    height: 100%;
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
  }
}

.container {
  max-width: 1200px;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 0 auto;
}

section {
  padding-bottom: 40px;

  &:nth-child(odd) {
    background: #FaFaFa;
  }
}

.brief-section {
  h2 {
    margin-top: 20px;
  }
}

.brief-item {
  margin-top: 60px;

  &:nth-child(2) {
    margin-top: 0;
  }

  img, video {
    width: 100%;
    height: auto;
  }

  &.stacked {
    .screenshot {
      min-height: auto;
      height: 5vw;
      overflow: hidden;
    }
  }

  &.left, &.right {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      max-width: 600px;
      width: 100%;
      margin-right: 50px;
    }

    video {
      height: 30vw;
      width: auto;
      max-height: 500px;
    }
  }

  &.right {
    p {
      margin-left: 50px;
      margin-right: 0;
    }
  }
}

.submission-form {
  margin: 60px auto 0;
  max-width: 600px;
}

.form-group {
  display: flex;
  flex-direction: column;

  .form-control {
    border: 1px solid #e0e0e0;
    background: #eee;
    width: 100%;
    min-width: 300px;
    padding: 12px;
    border-radius: 2px;
    outline: 0;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 100;

    &:focus {

    }
  }

  #submit {
    width: auto;
    padding: 25px 80px;
    background: #400095;
    color: white;
    border: none;
    border-radius: 5px;
    max-width: 250px;
    cursor: pointer;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    box-shadow: 0 6px #28005d;
    align-self: center;
    position: relative;
    outline: none;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }

    &:hover {
      box-shadow: 0 8px #28005d;
      top: -2px;
    }

    &:active {
      box-shadow: 0 0 #28005d;
      top: 6px;
    }
  }

  label {
    font-size: 22px;
    font-weight: 300;
    color: #999;
    margin-bottom: 10px;

    span {
      color: #400095;
      font-size: 12px;
      vertical-align: super;
    }
  }
}

.results {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.website-item {
  width: 31%;
  text-decoration: none;

  > div {
    text-transform: uppercase;
    margin-top: 10px;
    color: #444;
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 600;
  }

  &:hover {
    .preview-container {
      transform: translateY(-10px);
    }
  }
}

.preview-container {
  height: 270px;
  overflow: hidden;
  transition: .2s ease-in-out;

  img {
    width: 100%;
  }
}

.loading-msg {
  display: none;
  color: #965ce4;
  text-align: center;  
}

.success-msg  {
  display: none;
  color: #965ce4;
  text-align: center;
}


.fail-msg {
  display: none;
  color: #e45c5c;
  text-align: center;
}
